@use "@angular/material" as mat;

/* You can add global styles to this file, and also import other style files */
@import "assets/styles/variables";
@import "assets/styles/themes.scss";
@import "assets/styles/material.scss";
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "assets/fonts/gotham/fonts.scss";
@import "assets/fonts/cera-round/font.scss";
@import "assets/fonts/fontawesome/css/all.min.css";

:root {
    --md-sys-color-primary: var(--brand-color);
    --md-sys-color-secondary: var(--purple-color);
    --md-sys-color-tertiary: var(--pink-color);
}

$mat-theme: mat.define-theme();

@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($gotham-text-bold-font-path) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($gotham-text-medium-font-path) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($gotham-text-regular-font-path) format("truetype");
    font-weight: 350;
}


@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($gotham-text-light-font-path) format("truetype");
    font-weight: 325;
}


@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($cera-round-text-bold-font-path) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($cera-round-text-regular-font-path) format("truetype");
    font-weight: 350;
}


@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($cera-round-text-light-font-path) format("truetype");
    font-weight: 325;
}

@font-face {
    font-family: $font-family-brand;
    font-display: swap;
    src: url($cera-round-text-medium-font-path) format("truetype");
    font-weight: 400;
}

html,
body {
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    position: relative;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.4;
    font-weight: $font-weight-normal;

}

body *:not(i) {
    //font-family: gotham, "Geologica", "sans-serif" !important;
    font-family: cera-round, "Geologica", "sans-serif" !important;
}

body.swal2-shown > [aria-hidden='true'] {
    transition: 0.3s filter;
    filter: blur(20px);
}

.swal2-modal {
    min-width: 600px;
}

.not-sure-text {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
}

.mat-calendar-table-header th {
    color: purple; /* Change the color to purple */
}

.mat-datepicker-content {
    border-radius: 12px !important;
}

.mat-datepicker-content-container {
    height: auto !important;
    max-height: 80svh !important;
}

.mat-calendar-body-selected {
    background-color: #19A0A8 !important;
    box-shadow: none !important;
    font-weight: bold;
}

.mdc-button__label {
    font-family: Gotham Rounded, "Geologica", "sans-serif";
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: left;
}

.cdk-overlay-backdrop-showing {
    backdrop-filter: blur(20px) !important;
    pointer-events: none;
}

.alert-purple {
    background: #F1E6FB;
    color: #8301D2;
    border: none;
}

.link-primary {
    color: var(--purple-color) !important;
    text-decoration-color: var(--purple-color) !important;
    -webkit-text-decoration-color: var(--purple-color) !important;
}

.list-disc {
    list-style: disc !important;
}

.btn {
    border-radius: 12px;
    padding: .75rem 2rem;
    min-width: 100px!important;

    &.btn-sm {
        padding: .25rem 1rem;
        border-radius: 6px;
    }
}

.bg-gradient {
    background-image: var(--brand-color-secondary) !important;
    background-size: cover;
}

.btn-gradient {
    background-image: var(--brand-color-secondary) !important;
    background-size: cover;
    color: white !important;
    border: none;
    font-weight: bold;
}

.text-diagnosou-primary {
    color: var(--brand-color);
}
.text-diagnosou-secondary {
    color: var(--purple-color);
}
.text-diagnosou-accent {
    color: var(--pink-color);
}

.border-diagnosou-primary {
    border-color: var(--brand-color)!important;
}
.border-diagnosou-secondary {
    border-color: var(--purple-color)!important;
}
.border-diagnosou-accent {
    border-color: var(--pink-color)!important;
}

.break-words {
    word-break: break-word;
}

@for $i from 0 through 100 {
  .z-index-#{$i} {
    z-index: $i;
  }
    .font-size-#{$i} {
        font-size: $i + px;
    }
}

.shadow {
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.1);
}

.shadow-sm {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.w-fit {
    width: fit-content !important;
}

.h-fit {
    height: fit-content !important;
}

.spinner-primary {
    @include mat.progress-spinner-color($mat-theme, $color-variant: primary);
}

.events-none {
    pointer-events: none!important;
}

.backdrop-blur {
    backdrop-filter: blur(20px);
}

.pulsate {
    animation: pulsate 1.25s infinite;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.smartbanner__exit, .smartbanner__button {
    background: transparent!important;
    border: none!important;
}
.smartbanner__exit {
    margin: 0 8px!important;
    width: 16px!important;
    height: 16px!important;
    border: none!important;
    &:before, &:after {
        height: 16px!important;
    }
}
.smartbanner__info--title {
    color: var(--pink-color)!important;
    font-size: 14px!important;
    font-weight: bold;
}
.smartbanner__info--author, .smartbanner__info--price {
    font-size: 12px!important;
}
.smartbanner__icon {
    background-image: url("assets/images/App Logo.svg");
    background-repeat: no-repeat;
    width: 48px!important;
    height: 48px!important;
    background-size: contain!important;
}
.smartbanner {
    background: rgba(50, 50, 50, 1) !important;
    //backdrop-filter: blur(20px);
    //border-bottom: 1px solid lightgray;
    gap: .5rem;
}

// MEDIA QUERIES

@media screen and (max-width: 768px) {
    .swal2-modal {
        max-width: 95%;
        min-width: auto;
    }
}

@media screen and (min-width: 768px) {
    .mat-datepicker-content-touch {
        .mat-button, .mdc-button__label, .mat-fab, .mat-icon-button, .mat-mini-fab, .mat-raised-button,th,td, .mat-calendar-body-cell-content {
            font-size:24px!important;
        }
    }

}


[data-theme="dark"] {

    --background-color: #161616;
    --background-color-secondary: #2B2B2B;
    --background-color-tertiary: #1F1F1F;

    --border-color: #3E3E3E;
    --border-color-secondary: #606060;

    --text-color: #e1e1e1;
    --text-color-secondary: #8A8A8A;
    --cta-color: #fff;

    --brand-color-light: #b9dadf;
    --brand-color: #19A0A8;
    --brand-color-dark: #12767D;
    --brand-color-secondary: linear-gradient(.25turn, #1B9DA5 0%, #6C00D7 46.22%, #EE00BA 100%);

    --red-color: #FB0033;
    --dark-red-color: #ea3323;
    --green-color-light: #77BE37;
    --green-color: #28930D;
    --green-color-dark: #33731F;
    --blue-color: #007CC9;
    --purple-color: #6C00D7;
    --pink-color: #FF0092;
    --orange-color-light: #FFA500;
    --orange-color: #FF7800;
    --yellow-color: #F3AF01;
    --dark-gray: #2A2A2D;

    /* Header colors */
    --header-background-color: #111;

    /* Revolab table */
    --table-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.50);
    --table-header-text-color: #e1e1e1;

    /* Revolab tooltip */
    --tooltip-background-color: #e1e1e1;
    --tooltip-color: #161616;

    --mdc-checkbox-disabled: rgba(255,255,255,0.38);
}

[data-theme="light"] {

    --background-color: #fff;
    --background-color-secondary: #e3e3e3;
    --background-color-tertiary: #fff;

    --border-color: #8A8A8A;
    --border-color-secondary: #8A8A8A;

    --text-color: #161616;
    --text-color-secondary: #8A8A8A;
    --cta-color: #fff;
    --dark-gray: #2A2A2D;

    --brand-color-light: #b9dadf;
    --brand-color: #19A0A8;
    --brand-color-dark: #12767D;
    --brand-color-secondary: linear-gradient(.25turn, #1B9DA5 0%, #6C00D7 46.22%, #EE00BA 100%);

    --red-color: #FB0033;
    --dark-red-color: #ea3323;
    --green-color-light: #77BE37;
    --green-color: #28930D;
    --green-color-dark: #33731F;
    --blue-color: #007CC9;
    --purple-color: #6C00D7;
    --pink-color: #FF0092;
    --orange-color-light: #FFA500;
    --orange-color: #FF7800;
    --yellow-color: #F3AF01;

     /* Header colors */
     --header-background-color: #F3F3F3;

    /* Revolab table */
    --table-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
    --table-header-text-color: #e1e1e1;

    /* Revolab tooltip */
    --tooltip-background-color: #161616;
    --tooltip-color: #e1e1e1;

    --mdc-checkbox-disabled: rgba(0,0,0,0.38);
}
